<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO.ADITIVOS_DE_CONTRATO')"
      :subtitulo="$t('ACADEMICO.ADITIVO_DE_CONTRATO_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-2 ml-md-4"
        >
          <div></div>
          <b-button variant="info" @click="irParaCriarAditivo">
            {{ $t('ACADEMICO.NOVO_ADITIVO_TURMAS') }}
          </b-button>
        </div>
      </template>
    </topbar>
    <tabela-contratos-com-aditivos
      :itens="contratos"
      @abrirModal="abrirModalAditivos"
    />
    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getContratosComAditivo"
    />
    <modal-aditivos
      @fechar="fecharModalAditivos"
      :exibir="mostrarModalAditivos"
      :contratoId="contratoId"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import rotas from '@/common/utils/rotas';

// Services
import ContratosService from '@/common/services/contratos/contratos.service';

// Components:
import Permissoes from '@/components/mixins/permissoes';
import TabelaContratosComAditivos from './components/tabelas/TabelaContratosComAditivos';
import ModalAditivos from './components/modais/ModalAditivos';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  mixins: [Permissoes],
  props: {
    contrato: { type: Object, default: Object },
  },
  components: {
    Topbar,
    TabelaContratosComAditivos,
    ModalAditivos,
    Paginacao,
  },
  data() {
    return {
      mostrarModalAditivos: false,
      contratoId: '',
      contratos: [],
      aditivos: [],
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ADITIVOS_DE_CONTRATO') },
      ],
    };
  },
  mounted() {
    this.getContratosComAditivo();
  },
  methods: {
    getContratosComAditivo() {
      this.$store.dispatch(START_LOADING);
      ContratosService.buscarTodosContratosQuePossuemAditivo(this.paginacao)
        .then(({ data }) => {
          this.contratos = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    //AÇOES
    abrirModalAditivos(contrato) {
      this.contratoId = contrato.id;
      this.mostrarModalAditivos = true;
    },

    fecharModalAditivos() {
      this.contratoId = '';
      this.mostrarModalAditivos = false;
    },

    // aux
    irParaCriarAditivo() {
      rotas.irPara('formulario-aditivo-turma');
    },
  },
};
</script>
