<template>
  <b-table
    head-variant="light"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    :fields="fields"
    :items="itens"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-button
        variant="primary"
        class=""
        @click="abrirModalAditivosDoContrato(item.item)"
        >{{ $t('ACADEMICO.VISUALIZAR_ADITIVOS') }}</b-button
      >
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'TabelaContratosComAditivos',
  components: {},
  props: {
    itens: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'codigoContrato',
          label: this.$t('ACADEMICO.CODIGO'),
        },
        {
          key: 'aluno',
          label: this.$t('GERAL.NOME'),
        },
        {
          key: 'tipoContratoDescricao',
          label: this.$t('GERAL.DESCRICAO'),
        },
        {
          key: 'statusDescricao',
          label: this.$t('ACADEMICO.STATUS'),
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    abrirModalAditivosDoContrato(item) {
      this.$emit('abrirModal', item);
    },
  },
};
</script>
