<template>
  <div>
    <modal
      id="modalAdicionarPrecos"
      :titulo="$t('ACADEMICO.ADITIVOS_DE_CONTRATO')"
      :exibir="exibir"
      @fechar="fecharModal"
      :campos="$refs"
      tamanho="lg"
    >
      <listagem-aditivo :itens="aditivosContrato" />
      <template #modal-footer>
        <b-button  variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
      </template>
    </modal>
  </div>
</template>

<script>
//AUX
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services
import ContratosService from '@/common/services/contratos/contratos.service';

// COMPONENTS
import Modal from '@/components/modal/Modal.vue';
import ListagemAditivo from '../tabelas/ListagemAditivo.vue';
export default {
  name: 'ModalAditivosContratoPorTurma',
  components: {
    Modal,
    ListagemAditivo,
  },
  props: {
    exibir: { type: Boolean, default: true },
    contratoId: { type: String, default: String },
  },
  data() {
    return {
      aditivosContrato: [],
    };
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
      { titulo: this.$t('ACADEMICO.ADITIVOS_DE_CONTRATO') },
    ]);
  },
  computed: {
    titulo() {
      return this.$t('ACADEMICO.ADITIVOS_DE_CONTRATO');
    },
  },
  watch: {
    contratoId: 'getAditivos',
  },
  methods: {
    getAditivos(value) {
      if (value != '') {
        this.$store.dispatch(START_LOADING);
        ContratosService.buscarAditivosPorContratoId(this.contratoId)
          .then(({ data }) => {
            this.aditivosContrato = data.aditivosContratos;
          })
          .catch((err) => {
          this.aditivosContrato = [];
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
    // MODAL
    fecharModal() {
      this.aditivosContrato = [];
      this.$emit('fechar');
    },
  },
};
</script>
